import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { colors } from './colors';
import fonts from './fonts';
import { TextSMMedium } from './Texts';
import React, { CSSProperties, useState } from 'react';
import CheckboxSelected from './assets/checkbox-selected.svg';
import CheckboxEmpty from './assets/checkbox-empty.svg';
import RadiobuttonSelected from './assets/radiobutton-selected.svg';
import RadiobuttonEmpty from './assets/radiobutton-empty.svg';
import ErrorIcon from './assets/icon-input-error.svg';
import { View } from './View';
import { CornerRadius, Spacing } from './spacing';
import { HevyTooltip } from './HevyTooltip';
import { CustomizableIcon, CustomizableIconType } from './CustomizableIcon';
import { FlexRow } from './Row';
import Link from 'next/link';

const Input = styled.input`
  outline: none;
  border: none;
  box-sizing: border-box;
  width: 100%;
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  color: ${colors.neutral900};
  font-size: 14px;
  padding: 0;
  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }
`;

export const TextAreaAutosize = styled(TextareaAutosize)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  height: 40px;
  padding: ${Spacing.md}px ${Spacing.md}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.sm}px ${Spacing.sm}px;
  }
  border-radius: 6px;
  box-sizing: border-box;
  resize: vertical;
  font-size: 14px;
  outline: none;
  color: ${colors.neutral900};
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  border: 1px solid ${colors.neutral200};
  :focus {
    border: 1px solid ${colors.primary500};
    outline: 1px solid ${colors.primary500};
  }

  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  height: 40px;
  padding: ${Spacing.md}px ${Spacing.md}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.sm}px ${Spacing.sm}px;
  }
  border-radius: 6px;
  box-sizing: border-box;
  resize: vertical;
  font-size: 14px;
  outline: none;
  color: ${colors.neutral900};
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  border: 1px solid ${colors.neutral200};

  :focus {
    border: 1px solid ${colors.primary500};
    outline: 1px solid ${colors.primary500};
  }

  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }
`;

export const FormInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  height: 40px;
  padding: ${Spacing.md}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.sm}px ${Spacing.sm}px;
  }
  border-radius: ${CornerRadius.sm}px;
  box-sizing: border-box;
  font-size: 14px;
  outline: none;
  color: ${colors.neutral900};
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  border: 1px solid ${colors.neutral200};

  :focus {
    border: 1px solid ${colors.primary500};
    outline: 1px solid ${colors.primary500};
  }

  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }

  :disabled {
    color: ${colors.neutral400};
  }
`;

export const ExerciseNoteInput = styled.textarea`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 100%;
  padding: ${Spacing.md}px ${Spacing.md}px ${0}px ${Spacing.md}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.sm}px ${Spacing.sm}px;
  }
  height: inherit;
  border-radius: 6px;
  box-sizing: border-box;
  resize: vertical;
  font-size: 14px;
  outline: none;
  font-family: ${fonts.regular.family};
  font-weight: ${fonts.regular.weight};
  border: 1px solid ${colors.neutral200};
  color: ${colors.neutral900};

  :focus {
    border: 1px solid ${colors.primary500};
    outline: 1px solid ${colors.primary500};
  }

  ::placeholder {
    color: ${colors.neutral400};
    opacity: 1; /* Firefox */
  }
`;

export const LabelledFormInput = (
  props: {
    label: string;
    containerStyle?: CSSProperties;
    errorMessage?: string;
    secondaryActionLabel?: string;
    secondaryActionPressed?: () => void;
    tabIndex?: number;
  } & React.ComponentPropsWithRef<'input'>,
) => (
  <View style={{ position: 'relative', ...props.containerStyle }}>
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <TextSMMedium style={{ marginTop: '12px', marginBottom: '4px' }}>{props.label}</TextSMMedium>
      {props.secondaryActionLabel && (
        <TextSMMedium
          style={{ marginTop: '12px', marginBottom: '4px' }}
          onClick={props.secondaryActionPressed}
        >
          <Link href="#">{props.secondaryActionLabel}</Link>
        </TextSMMedium>
      )}
    </View>

    <FormInput
      tabIndex={props.tabIndex}
      style={
        !!props.errorMessage
          ? {
              border: `1px solid ${colors.error500}`,
              outline: `1px solid ${colors.error500}`,
            }
          : {}
      }
      {...props}
    />
    {props.errorMessage && (
      <FlexRow
        style={{
          gap: '4px',
          marginTop: '6px',
          marginLeft: '3px',
        }}
      >
        <ErrorIcon />
        <TextSMMedium style={{ color: colors.error500, flex: 1 }}>
          {props.errorMessage}
        </TextSMMedium>
      </FlexRow>
    )}
  </View>
);

export const StyledCheckbox = (props: {
  label?: string | JSX.Element;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: React.ComponentPropsWithRef<'label'>['style'];
  errorMessage?: string;
  tabIndex?: number;
}) => (
  <div style={{ height: !props.errorMessage ? 18 : 36, ...props.style }}>
    <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <FormInput
        tabIndex={props.tabIndex}
        type={'checkbox'}
        checked={props.checked}
        readOnly={props.onChange === undefined}
        onChange={props.onChange}
        style={{ display: 'none' }}
      />
      <div style={{ display: 'inline' }}>
        {props.checked ? <CheckboxSelected /> : <CheckboxEmpty />}
      </div>
      {props.label && (
        <div style={{ marginLeft: Spacing.sm, display: 'inline', width: '100%' }}>
          {props.label}
        </div>
      )}
    </label>
    {props.errorMessage && (
      <FlexRow style={{ gap: Spacing.xs, marginTop: 6 }}>
        <ErrorIcon />
        <TextSMMedium style={{ color: colors.error500 }}>{props.errorMessage}</TextSMMedium>
      </FlexRow>
    )}
  </div>
);

export const StyledRadioButton = ({
  style,
  checked,
}: {
  checked: boolean;
  style?: CSSProperties;
}) => {
  return (
    <div style={{ ...style, height: 18, width: 18 }}>
      {checked ? <RadiobuttonSelected /> : <RadiobuttonEmpty />}
    </div>
  );
};

const InputContainer = styled(FlexRow)`
  flex: 1;
  height: 40px;
  @-moz-document url-prefix() {
    /* Without this the clear text button is misplaced on firefox */
    margin-right: -20px;
  }
`;

interface IconInputProps {
  value: string;
  iconType?: CustomizableIconType;
  placeholder?: string;
  onChange?: (value: string) => void;
  spellCheck?: boolean;
  autoCapitalize?: string;
  style?: CSSProperties;
  enableClearButton?: boolean;
  disabled?: boolean;
  iconTint?: string;
  inputColor?: string;
  focusedBorderColor?: string;
  unfocusedBorderColor?: string;
  disabledIconTooltipText?: string;
  showDisabledIcon?: boolean;
  autoFocus?: boolean;
}

export const IconFormInput = ({
  value,
  iconType,
  iconTint,
  placeholder,
  onChange,
  spellCheck,
  autoCapitalize,
  style,
  enableClearButton,
  disabled,
  disabledIconTooltipText,
  showDisabledIcon,
  inputColor,
  focusedBorderColor,
  unfocusedBorderColor,
  autoFocus,
}: IconInputProps) => {
  const [isHoveringClearButton, setIsHoveringClearButton] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FlexRow
      style={{
        borderRadius: CornerRadius.sm,
        ...style,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: isFocused
          ? focusedBorderColor ?? colors.primary500
          : unfocusedBorderColor ?? colors.neutral200,
        outline: isFocused ? `1px solid ${focusedBorderColor ?? colors.primary500}` : 'none',
      }}
    >
      <InputContainer>
        {!!iconType && (
          <CustomizableIcon
            type={iconType}
            style={{ marginLeft: Spacing.md }}
            tint={iconTint ?? colors.neutral900}
          />
        )}
        <Input
          autoFocus={autoFocus}
          style={{
            backgroundColor: colors.transparent,
            flex: 1,
            marginLeft: !!iconType ? Spacing.sm : Spacing.md,
            color: disabled ? colors.neutral400 : inputColor ?? colors.neutral900,
          }}
          placeholder={placeholder}
          value={value}
          spellCheck={spellCheck}
          autoComplete="off"
          onChange={e => {
            if (onChange) {
              onChange(e.currentTarget.value);
            }
          }}
          autoCapitalize={autoCapitalize}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={disabled}
        />
      </InputContainer>
      {!!enableClearButton && !!value.length && (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isHoveringClearButton ? colors.neutral300 : colors.neutral200,
            borderRadius: 10,
            height: 20,
            width: 20,
            cursor: 'pointer',
            margin: Spacing.sm,
          }}
          onMouseEnter={() => setIsHoveringClearButton(true)}
          onMouseLeave={() => setIsHoveringClearButton(false)}
          onClick={() => {
            setIsHoveringClearButton(false);
            if (onChange) {
              onChange('');
            }
          }}
        >
          <CustomizableIcon type="search-cross" tint={colors.neutral500} />
        </View>
      )}
      {showDisabledIcon && disabled && (
        <HevyTooltip
          direction="left"
          text={disabledIconTooltipText}
          enabled={!!disabledIconTooltipText}
        >
          <View style={{ pointerEvents: 'auto', padding: Spacing.xs, marginRight: Spacing.sm }}>
            <CustomizableIcon type="lock" tint={colors.neutral300}></CustomizableIcon>
          </View>
        </HevyTooltip>
      )}
    </FlexRow>
  );
};

interface SearchInputProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  style?: CSSProperties;
  inputColor?: string;
  focusedBorderColor?: string;
  unfocusedBorderColor?: string;
  autoFocus?: boolean;
}

export const SearchInput = ({
  value,
  placeholder,
  onChange,
  style,
  inputColor,
  focusedBorderColor,
  autoFocus,
  unfocusedBorderColor,
}: SearchInputProps) => {
  return (
    <IconFormInput
      autoFocus={autoFocus}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      style={style}
      spellCheck={false}
      iconType="search"
      iconTint={colors.neutral400}
      enableClearButton={true}
      inputColor={inputColor}
      focusedBorderColor={focusedBorderColor}
      unfocusedBorderColor={unfocusedBorderColor}
    ></IconFormInput>
  );
};
