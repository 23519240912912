import dayjs from 'dayjs';
import { Lookup } from 'hevy-shared/built/typeUtils';

// This the type of data that's been shown
export interface ClientMeasurement {
  date: string;
  weight?: string;
  bodyfat?: string;
  neck?: string;
  shoulder?: string;
  chest?: string;
  leftBicep?: string;
  rightBicep?: string;
  leftForearm?: string;
  rightForearm?: string;
  abdomen?: string;
  waist?: string;
  hips?: string;
  leftThigh?: string;
  rightThigh?: string;
  leftCalf?: string;
  rightCalf?: string;
  pictureUrl?: string;
}

export const clientMeasurementsList = [
  'weight',
  'bodyfat',
  'neck',
  'shoulder',
  'chest',
  'leftBicep',
  'rightBicep',
  'leftForearm',
  'rightForearm',
  'abdomen',
  'waist',
  'hips',
  'leftThigh',
  'rightThigh',
  'leftCalf',
  'rightCalf',
] as const;

export type ClientMeasurementKey = Lookup<typeof clientMeasurementsList>;

export const EmptyClientMeasurement: ClientMeasurement = {
  date: dayjs().format('YYYY-MM-DD'),
  weight: undefined,
  bodyfat: undefined,
  neck: undefined,
  shoulder: undefined,
  chest: undefined,
  leftBicep: undefined,
  rightBicep: undefined,
  leftForearm: undefined,
  rightForearm: undefined,
  abdomen: undefined,
  waist: undefined,
  hips: undefined,
  leftThigh: undefined,
  rightThigh: undefined,
  leftCalf: undefined,
  rightCalf: undefined,
  pictureUrl: undefined,
};

export const clientMeasurementKeyTitleMap: { [key in ClientMeasurementKey]: string } = {
  weight: 'Body Weight',
  bodyfat: 'Body Fat',
  neck: 'Neck',
  shoulder: 'Shoulder',
  chest: 'Chest',
  leftBicep: 'Left Bicep',
  rightBicep: 'Right Bicep',
  leftForearm: 'Left Forearm',
  rightForearm: 'Right Forearm',
  abdomen: 'Abdomen',
  waist: 'Waist',
  hips: 'Hips',
  leftThigh: 'Left Thigh',
  rightThigh: 'Right Thigh',
  leftCalf: 'Left Calf',
  rightCalf: 'Right Calf',
};
