import { userBodyMeasurement, userWeight } from 'utils/units';
import {
  BodyMeasurement,
  BodyMeasurementUnit,
  exactInchtoCm,
  exactLbsToKg,
  forceStringToNumber,
  PostBodyMeasurementRequest,
  WeightUnit,
} from 'hevy-shared';
import { ClientMeasurement } from './types';
import { stringToMaybeNumber } from 'utils/pureUtils';

export const isAtLeastOneMeasurementValid = (measurements: ClientMeasurement): boolean => {
  const m = measurements;

  // If all measurements are empty, return false.
  if (
    !m.pictureUrl &&
    !m.weight &&
    !m.bodyfat &&
    !m.neck &&
    !m.shoulder &&
    !m.chest &&
    !m.leftBicep &&
    !m.rightBicep &&
    !m.leftForearm &&
    !m.rightForearm &&
    !m.abdomen &&
    !m.waist &&
    !m.hips &&
    !m.leftThigh &&
    !m.rightThigh &&
    !m.leftCalf &&
    !m.rightThigh
  ) {
    return false;
  }

  if (!m.date) {
    return false; // Date is required, should never be empty per design.
  }

  return true;
};

export const isValidMeasurementInput = (input: string): boolean => {
  if (input.length > 6) return false;

  // Can only have one comma or period
  if (input.split('').filter(c => c === '.' || c === ',').length > 1) return false;

  return /^[0-9.,]*$/.test(input);
};

const normalizePercentMeasurement = (value?: string) => {
  const percent = stringToMaybeNumber(value);
  if (!percent) {
    return undefined;
  }

  return percent > 0 && percent <= 100 ? percent : undefined;
};

// Takes a weight as a string and normalizes it to a valid number in kg
const normalizeBodyWeightInput = (unit: WeightUnit, value?: string): number => {
  if (!value) return 0;

  const num = forceStringToNumber(value);
  const absNum = Math.abs(num);

  if (unit === 'lbs') {
    return exactLbsToKg(absNum);
  }

  return absNum;
};

const normalizeBodyMeasurementInput = (unit: BodyMeasurementUnit, value?: string): number => {
  if (!value) return 0;

  const num = forceStringToNumber(value);
  const absNum = Math.abs(num);

  if (unit === 'in') {
    return exactInchtoCm(absNum);
  }

  return absNum;
};

export const getClientMeasurement = (
  measurements: BodyMeasurement,
  measurementUnit: BodyMeasurementUnit,
  weightUnit: WeightUnit,
): ClientMeasurement => {
  const m = measurements;

  return {
    date: m.date,
    weight: m.weight_kg ? userWeight(m.weight_kg, weightUnit).toFixed(2) : undefined,
    bodyfat: m.fat_percent?.toFixed(2),
    neck: m.neck_cm ? userBodyMeasurement(m.neck_cm, measurementUnit).toFixed(2) : undefined,
    shoulder: m.shoulder_cm
      ? userBodyMeasurement(m.shoulder_cm, measurementUnit).toFixed(2)
      : undefined,
    chest: m.chest_cm ? userBodyMeasurement(m.chest_cm, measurementUnit).toFixed(2) : undefined,
    leftBicep: m.left_bicep_cm
      ? userBodyMeasurement(m.left_bicep_cm, measurementUnit).toFixed(2)
      : undefined,
    rightBicep: m.right_bicep_cm
      ? userBodyMeasurement(m.right_bicep_cm, measurementUnit).toFixed(2)
      : undefined,
    leftForearm: m.left_forearm_cm
      ? userBodyMeasurement(m.left_forearm_cm, measurementUnit).toFixed(2)
      : undefined,
    rightForearm: m.right_forearm_cm
      ? userBodyMeasurement(m.right_forearm_cm, measurementUnit).toFixed(2)
      : undefined,
    abdomen: m.abdomen ? userBodyMeasurement(m.abdomen, measurementUnit).toFixed(2) : undefined,
    waist: m.waist ? userBodyMeasurement(m.waist, measurementUnit).toFixed(2) : undefined,
    hips: m.hips ? userBodyMeasurement(m.hips, measurementUnit).toFixed(2) : undefined,
    leftThigh: m.left_thigh
      ? userBodyMeasurement(m.left_thigh, measurementUnit).toFixed(2)
      : undefined,
    rightThigh: m.right_thigh
      ? userBodyMeasurement(m.right_thigh, measurementUnit).toFixed(2)
      : undefined,
    leftCalf: m.left_calf
      ? userBodyMeasurement(m.left_calf, measurementUnit).toFixed(2)
      : undefined,
    rightCalf: m.right_calf
      ? userBodyMeasurement(m.right_calf, measurementUnit).toFixed(2)
      : undefined,
  };
};

export const getPostableBodyMeasurement = (
  measurement: ClientMeasurement,
  measurementUnit: BodyMeasurementUnit,
  weightUnit: WeightUnit,
): PostBodyMeasurementRequest | undefined => {
  const m = measurement;

  const updateMeasurement = {
    weight_kg: normalizeBodyWeightInput(weightUnit, m.weight),
    fat_percent: normalizePercentMeasurement(m.bodyfat),
    neck_cm: normalizeBodyMeasurementInput(measurementUnit, m.neck),
    shoulder_cm: normalizeBodyMeasurementInput(measurementUnit, m.shoulder),
    chest_cm: normalizeBodyMeasurementInput(measurementUnit, m.chest),
    left_bicep_cm: normalizeBodyMeasurementInput(measurementUnit, m.leftBicep),
    right_bicep_cm: normalizeBodyMeasurementInput(measurementUnit, m.rightBicep),
    left_forearm_cm: normalizeBodyMeasurementInput(measurementUnit, m.leftForearm),
    right_forearm_cm: normalizeBodyMeasurementInput(measurementUnit, m.rightForearm),
    abdomen: normalizeBodyMeasurementInput(measurementUnit, m.abdomen),
    waist: normalizeBodyMeasurementInput(measurementUnit, m.waist),
    hips: normalizeBodyMeasurementInput(measurementUnit, m.hips),
    left_thigh: normalizeBodyMeasurementInput(measurementUnit, m.leftThigh),
    right_thigh: normalizeBodyMeasurementInput(measurementUnit, m.rightThigh),
    left_calf: normalizeBodyMeasurementInput(measurementUnit, m.leftCalf),
    right_calf: normalizeBodyMeasurementInput(measurementUnit, m.rightCalf),
    picture_url: m.pictureUrl,
  };

  // If all measurements are empty, return undefined.
  if (Object.values(updateMeasurement).every(m => m === undefined)) {
    return undefined;
  }

  return { date: measurement.date, ...updateMeasurement };
};
